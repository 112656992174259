<template>
  <v-container>
    <v-layout justify-center>
      <v-flex xs12 sm12 md11 lg9 xl7>
        <v-data-table
          dense
          :headers="cabecalho"
          :items="compUsuariosLista"
          class="elevation-10"
          :search="busca"
          :loading="compUsuariosProgress"
          loading-text="Carregando..."
          :footer-props="{
            'items-per-page-options': [10, 20, 30],
          }"
          sortBy="id"
          sort-desc
        >
          <template v-slot:[`item.whatsapp`]="{ item }">
            {{ metFormatarTelefone(item.whatsapp) }}
          </template>

          <template v-slot:progress>
            <v-progress-linear
              color="primary"
              :height="4"
              indeterminate
            ></v-progress-linear>
          </template>

          <template v-slot:top>
            <v-toolbar color="primary" dark dense>
              <v-icon class="mr-2">mdi-cash-register</v-icon>
              <v-toolbar-title>Usuários de Caixa</v-toolbar-title>
              <v-spacer></v-spacer>

              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-icon
                    class="mr-2"
                    v-bind="attrs"
                    v-on="on"
                    @click="metRefresh()"
                  >
                    mdi-refresh
                  </v-icon>
                </template>
                <span>Refresh</span>
              </v-tooltip>

              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-icon
                    class="mr-2"
                    v-bind="attrs"
                    v-on="on"
                    @click="metVoltar()"
                  >
                    mdi-arrow-left-circle-outline
                  </v-icon>
                </template>
                <span>Voltar</span>
              </v-tooltip>
            </v-toolbar>
            <v-container>
              <v-row dense>
                <v-col cols="auto" md="4" class="ml-1 mb-0" align-self="end">
                  <v-text-field
                    v-model="busca"
                    append-icon="mdi-magnify"
                    label="Busca"
                    single-line
                    hide-details
                  >
                  </v-text-field>
                </v-col>
              </v-row>
            </v-container>
          </template>

          <template v-slot:[`item.actions`]="{ item }">
            <v-tooltip left>
              <template v-slot:activator="{ on, attrs }">
                <v-icon
                  dense
                  class="mr-2"
                  v-bind="attrs"
                  v-on="on"
                  @click="metSelecionarUsuario(item)"
                  color="green darken-1"
                >
                  mdi-archive-edit-outline
                </v-icon>
              </template>
              <span>Escolher Caixas</span>
            </v-tooltip>
          </template>
          <template v-slot:[`item.caixas`]="{ item }">
            <v-chip color="green" text-color="black" small>
              {{ contarCaixas(item.id) }}
            </v-chip>
          </template>
        </v-data-table>
      </v-flex>
    </v-layout>
  </v-container>
</template>

<script>
import { createNamespacedHelpers } from "vuex";
import DatasMixin from "@/mixins/DatasMixin";

const { mapState, mapActions, mapGetters } =
  createNamespacedHelpers("contUsuarios");

export default {
  mixins: [DatasMixin],
  name: "ContUsuariosLista",
  data: () => ({
    busca: "",
    cabecalho: [
      { text: "Código", value: "id", align: "start" },
      { text: "Caixas", value: "caixas", align: "start", sortable: false },
      {
        text: "Nome",
        align: "start",
        sortable: true,
        value: "nome",
      },
      { text: "Usuário", value: "usuario", align: "start" },
      { text: "E-mail", value: "email", align: "start", sortable: false },
      { text: "Whatsapp", value: "whatsapp", align: "start", sortable: false },
      { text: "Ações", value: "actions", sortable: false },
    ],
    varDeletarId: undefined,
    varDialogDeletar: false,
  }),

  computed: {
    ...mapState([
      "staErro",
      "staUsuarios",
      "staUsuariosGrupos",
      "staUsuarioSelecionado",
      "staUsuarioCaixas",
    ]),
    ...mapGetters(["getUsuarios", "getCaixasSelect"]),

    compUsuariosLista() {
      return this.getUsuarios;
    },
    compUsuariosProgress() {
      return this.staUsuarios === null || this.staUsuarios.length === 0;
    },
  },

  created() {
    this.actUsuariosLista({ licenca: this.$store.state.login.licenca });
    this.actUsuariosGrupos({ licenca: this.$store.state.login.licenca });
    this.actUsuarioCaixas({
      licenca: this.$store.state.login.licenca,
    });
  },

  watch: {
    erro() {
      if (this.staErro) {
        this.$root.snackbar.show({
          type: "danger",
          message: this.staErro,
        });
        setTimeout(() => {
          this.$router.push({ name: "Login" });
        }, 3500);
      }
    },
  },

  methods: {
    ...mapActions([
      "actUsuariosLista",
      "actUsuarioSelecionado",
      "actUsuarioResetar",
      "actUsuarioLixeira",
      "actUsuarioDelete",
      "actAdminUsuariosLista",
      "actUsuariosGrupos",
      "actUsuarioCaixas",
    ]),

    contarCaixas(idUsuario) {
      if (!this.staUsuarioCaixas || this.staUsuarioCaixas.length === 0) {
        return 0;
      }

      return this.staUsuarioCaixas.filter(
        (caixa) => caixa.id_usuario === idUsuario
      ).length;
    },

    metFormatarTelefone(numero) {
      if (!numero) return "";
      const apenasNumeros = numero.replace(/\D/g, "");

      return apenasNumeros.replace(
        /(\d{2})(\d{2})(\d{1})(\d{4})(\d{4})/,
        "+$1 ($2) $3 $4-$5"
      );
    },

    metPermissao(field) {
      if (this.perm.some((t) => t.field == field)) {
        const permObject = this.perm.filter((t) => t.field == field);
        if (permObject[0].group[0] == this.$store.state.login.usuario.grupo) {
          return true;
        } else {
          return false;
        }
      } else {
        return false;
      }
    },

    async metSelecionarUsuario(usuario) {
      if (usuario && usuario.id) {
        await this.actUsuarioSelecionado({
          id: usuario.id,
          licenca: this.$store.state.login.licenca,
        });
      } else {
        await this.actUsuarioResetar();
      }
      this.$router.push({ name: "ContUsuariosForm" });
    },

    metDeletarUsuario() {
      this.actUsuarioDelete({
        id: this.varDeletarId,
        licenca: this.$store.state.login.licenca,
      });
      this.varDialogDeletar = false;
    },

    metFecharDialogDeletar() {
      this.varDialogDeletar = false;
    },

    metDialogDeletarUsuario(item) {
      this.varDeletarId = item.id;
      this.varDialogDeletar = true;
    },

    metLixeiraColor(lixeira) {
      if (lixeira) return "orange darken-2";
      else return "red darken-1";
    },

    metLixeira(usuario) {
      try {
        this.actUsuarioLixeira({
          usuario: usuario,
          licenca: this.$store.state.login.licenca,
          modificacao: this.$store.state.login.usuario.usuario,
        });
        this.$root.snackbar.show({
          type: "success",
          message: "Movida para Lixeira.",
        });
      } catch (error) {
        console.log(error);
      }
    },

    metRefresh() {
      this.actUsuariosLista({ licenca: this.$store.state.login.licenca });
      this.$root.snackbar.show({
        type: "info",
        message: "Lista atualizada!",
      });
    },

    metVoltar() {
      this.$router.back();
    },
  },
};
</script>

<style></style>
